//Styling
import {Row,Col} from 'react-bootstrap';
import '../Toggle.css';

//Libraries
import { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip';
import Toggle from 'react-toggle'

//Amplify/AWS
import  { API } from 'aws-amplify'

//Misc
import {STATION_MAP} from '../Stores/StationStore'

//Graphql
import { batchUpdateStations } from '../graphql/mutations';




/* Station Data Dict example
apNames: Array(9) [ "KN-PL-TSR02", "KN-CO-AP07", "KN-ST-AP06", … ]
​
ids: Array(9) [ {…}, {…}, {…}, … ]
​
raw: Array(9) [ {…}, {…}, {…}, … ]
​
scramDown: "true"
​
stationName: "kennedy"
​
statuses: String { "9/9 AP's Confirmed State,\n2 Not Reachble" }

*/

function TableItem(props){
	const station 		= props.station;
	const scramDown 	= station.scramDown;
	const raw 			= station.raw
	const [toggleState,setToggleState] 		= useState(scramDown);


	// to be used to set the intial position of the toggle

	const displayName = STATION_MAP[station.stationName];
	const apIcons     = []
	const confirmedStates = raw.reduce((statuses,status)=> statuses.concat(status.confirmedState),[])
	//icon generation
	raw.forEach(function(item){
		//TODO: add states to tool tips?
		var tooltip = "Name: "+item.apName+"<br>Type: "+item.radioType.toUpperCase();
		if(item.reachable===false && item.confirmedState){
			apIcons.push(<span data-tip = {tooltip} className="ap unreachable"></span>)
		}
		else if(item.confirmedState===false){
			apIcons.push(<span data-tip = {tooltip} className="ap pending"></span>)
		}
		else if (item.confirmedState===true && item.scramDown===true){
			apIcons.push(<span data-tip = {tooltip} className="ap silent"></span>)
		}
		else if (item.confirmedState===true && item.scramDown===false ){
			apIcons.push(<span data-tip = {tooltip} className="ap broadcasting"></span>
			)
		}
		else {

		}
	})
	useEffect(()=>{
		setToggleState(scramDown)

	},[scramDown])

	async function  scramRequest(){
			
		   const resp = await API.graphql({query: batchUpdateStations,variables:{scramTables:raw,request:scramDown==='true'? false:true}}).catch(function (err){console.log(err)})
		   
		   return resp
		}

	return( 
		<Row className="TableItem">

			<Col className="status-cell"> 
				<Row className="justify-content-md-center">{confirmedStates.every( v=> v=== true)?
											<Toggle	
											defaultChecked={scramDown ==='true'? false: true}
											checked={toggleState ==='true'? false: true}
											onChange={()=>{
															setToggleState(scramDown==='true'?'false':'true')
															scramRequest();
															}}
											icons={false}
										/>:<Toggle disabled = {true} 
											defaultChecked={scramDown==='true'? false: true}

											checked={toggleState==='true'? false: true}
											icons={false}/>
					}
					</Row>
			</Col>
			<Col>
				<Row className=" station-name justify-content-md-left">{displayName}</Row>

			</Col>
			<Col>
				<Row className = "status-cell">{apIcons}</Row>
			</Col>
			<ReactTooltip multiline={true} />
		</Row>);
}
export default TableItem;
