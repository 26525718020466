//Styling
import {Navbar} from 'react-bootstrap';



function NavBar(props){
		return (
			<Navbar bg="dark" variant="dark" color = "red">
				<Navbar.Brand>
					<img alt = "Crosslinx logo"
					width = "160"
					height = "50"
					src ="../../crosslinxLogo.png"
					className="d-inline-block align-top">
					</img>
					
				</Navbar.Brand>
				<Navbar.Brand className="nav-brand-text"><h1>{props.title}</h1></Navbar.Brand>
				
			</Navbar>
		);
}
export default NavBar