//Styling
import {Modal} from 'react-bootstrap';



function ModalGen(props){
    return props.data!==null ?(     
         <Modal show={props.show} onHide={props.callback}>
            <Modal.Header closeButton>
            <Modal.Title>{props.data.title}</Modal.Title>
            </Modal.Header>
            {props.data.body}
            
      </Modal>):(<Modal></Modal>)
}

export default ModalGen