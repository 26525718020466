
//Map of the station names that come in with the data and the Display names
export const STATION_MAP = {	
	"mt-dennis"			: "801 - Mount Dennis",
	"keelesdale" 		: "802 - Keelesdale",
	"caledonia"			: "803 - Caledonia",
	"fairbank"			: "804 - Fairbank",
	"oakwood"			: "805 - Oakwood",
	"cedarvale" 		: "806 - Cedarvale",
	"forest-hill"		: "807 - Forest Hill",
	"chaplin"			: "808 - Chaplin",
	"avenue" 			: "809 - Avenue",
	"eglinton" 			: "810 - Eglinton",
	"mt-pleasant"		: "811 - Mount Pleasant",
	"leaside"			: "812 - Leaside",
	"laird"				: "813 - Laird",
	"sunnybrook-park"	: "701 - Sunnybrook Park",
	"science-centre"	: "814 - Science Centre",
	"aga-kahn"			: "702 - Aga Khan",
	"wynford"			: "703 - Wynford",
	"sloane"			: "704 - Sloane",
	"o-connor"			: "705 - O'Connor",
	"pharmacy"			: "706 - Pharmacy",
	"hakimi-lebovic"	: "707 - Hakimi Lebovic",
	"golden-mile"		: "708 - Golden Mile",
	"birchmount"		: "709 - Birchmount",
	"ionview"			: "710 - Ionview",
	"kennedy"			: "815 - Kennedy",
}