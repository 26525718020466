/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onAnyUpdateScramFrontendTable = /* GraphQL */ `
  subscription OnAnyUpdateScramFrontendTable {
    onAnyUpdateSCRAMFrontendTable {
      id
      stationName
      apName
      adminDown
      confirmedState
      ignored
      reachable
      scramDown
      stateAuthor
      radioType
      ip
      lastUpdatedTimestamp
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateScramFrontendTable = /* GraphQL */ `
  subscription OnCreateScramFrontendTable {
    onCreateSCRAMFrontendTable {
      id
      stationName
      apName
      adminDown
      confirmedState
      ignored
      reachable
      scramDown
      stateAuthor
      radioType
      ip
      lastUpdatedTimestamp
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateScramFrontendTable = /* GraphQL */ `
  subscription OnUpdateScramFrontendTable {
    onUpdateSCRAMFrontendTable {
      id
      stationName
      apName
      adminDown
      confirmedState
      ignored
      reachable
      scramDown
      stateAuthor
      radioType
      ip
      lastUpdatedTimestamp
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteScramFrontendTable = /* GraphQL */ `
  subscription OnDeleteScramFrontendTable {
    onDeleteSCRAMFrontendTable {
      id
      stationName
      apName
      adminDown
      confirmedState
      ignored
      reachable
      scramDown
      stateAuthor
      radioType
      ip
      lastUpdatedTimestamp
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
