/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const batchUpdateStations = /* GraphQL */ `
  mutation BatchUpdateStations(
    $scramTables: [batchUpdateStationsInput]
    $request: Boolean
  ) {
    batchUpdateStations(scramTables: $scramTables, request: $request) {
      id
      stationName
      apName
      adminDown
      confirmedState
      ignored
      reachable
      scramDown
      stateAuthor
      radioType
      ip
      lastUpdatedTimestamp
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createScramFrontendTable = /* GraphQL */ `
  mutation CreateScramFrontendTable(
    $input: CreateSCRAMFrontendTableInput!
    $condition: ModelSCRAMFrontendTableConditionInput
  ) {
    createSCRAMFrontendTable(input: $input, condition: $condition) {
      id
      stationName
      apName
      adminDown
      confirmedState
      ignored
      reachable
      scramDown
      stateAuthor
      radioType
      ip
      lastUpdatedTimestamp
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateScramFrontendTable = /* GraphQL */ `
  mutation UpdateScramFrontendTable(
    $input: UpdateSCRAMFrontendTableInput!
    $condition: ModelSCRAMFrontendTableConditionInput
  ) {
    updateSCRAMFrontendTable(input: $input, condition: $condition) {
      id
      stationName
      apName
      adminDown
      confirmedState
      ignored
      reachable
      scramDown
      stateAuthor
      radioType
      ip
      lastUpdatedTimestamp
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteScramFrontendTable = /* GraphQL */ `
  mutation DeleteScramFrontendTable(
    $input: DeleteSCRAMFrontendTableInput!
    $condition: ModelSCRAMFrontendTableConditionInput
  ) {
    deleteSCRAMFrontendTable(input: $input, condition: $condition) {
      id
      stationName
      apName
      adminDown
      confirmedState
      ignored
      reachable
      scramDown
      stateAuthor
      radioType
      ip
      lastUpdatedTimestamp
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
