//Styling
import './App.css';
import './Toggle.css';
import { Button, Col, Modal, Row, Container, ModalBody } from 'react-bootstrap';

//Libraries
import { useState, useEffect } from 'react';
import React from 'react';

//Components
import NavBar from './Components/Nav';
import Toggle from 'react-toggle'
import Table from './Components/Table.js'
import ModalGen from './Components/modalGen'

//Amplify/AWS
import { API } from 'aws-amplify'
import Amplify from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import awsconfig from './aws-exports';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { AmplifySignIn, AmplifyAuthenticator} from '@aws-amplify/ui-react';

//Graphql
import { batchUpdateStations } from './graphql/mutations';




Amplify.configure(awsconfig);
Auth.configure(awsconfig)

function App() {


  //Auth
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData)
    });
  });


  //ALl
  const [allStationData, setAllStationData] = useState([])


  //Sidebar Modal Generation
  const [helpModal, setHelpModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const handleHelpShow = () => setHelpModal(true);
  const handleHelpClose = () => setHelpModal(false)


  //Shutdown Modal
  const [shutdownModal, setShutdownModal] = useState(false);
  const handleShutdownShow = () => setShutdownModal(true);
  const handleShutdownHide = () => setShutdownModal(false);

  const [shutdownLoading, setShutdownLoading] = useState(false);
  const handleShutdownLoading = () => setShutdownLoading(true);
  const handleShutdownReady = () => setShutdownLoading(false);

  // Functions
  async function killStations() {
    handleShutdownLoading();
    var shutdown = [];
    shutdown = allStationData.filter((item)=>{
        if(item.scramDown===false)return true;
        return false;
      });

    // Call to API
    await API.graphql({ query: batchUpdateStations, variables: { scramTables: shutdown, request: true } }).catch(function (err) { });
    handleShutdownHide();
    handleShutdownReady();

  }
  async function restoreStations() {
    handleShutdownLoading();
    var restore = [];
    restore = allStationData.filter((item)=>{
        if(item.scramDown===true)return true;
        return false;
      });

    await API.graphql({ query: batchUpdateStations, variables: { scramTables: restore, request: false } }).catch(function (err) { });
    handleShutdownHide();
    handleShutdownReady();


  }


  //Modal
  const shutDownConfirmationModal = (
    <Modal show={shutdownModal} onHide={handleShutdownHide} >
      <Modal.Header closeButton>
        <Modal.Title>SHUTDOWN ALL STATIONS?</Modal.Title>
      </Modal.Header>
      <Modal.Body><b>Are you sure you want to {"Shutdown all station radios?"} </b></Modal.Body>
      <Button onClick={() => {
        killStations();
        handleShutdownHide();
      }
      }
        variant="danger">
        Confirm SHUTDOWN</Button>
      <Modal.Footer>
        <Button onClick={restoreStations}
          variant="success">
          RESTORE ALL</Button>
        <Button onClick={handleShutdownHide} variant="warning">CANCEL</Button>
      </Modal.Footer>
    </Modal>
  )


  return authState === AuthState.SignedIn && user ? (
    <div className="App">

      {/* Top Nav */}
      <NavBar bg="dark" title="CROSSLINX EMERGENCY RADIO SHUTDOWN"></NavBar>

      {/* Main Body Container */}
      <Container className="content" fluid>
        <Row className="root-Row">
          <Col className="side-bar justify-content-md-left d-lg-table-cell d-none">
            <Row className="sidebar-item">
              <a onClick={
                () => {
                  var red = "Toggle red:"
                  var green = "Toggle green:"
                  setModalData({
                    "title": "Help",
                    "body":
                      <ModalBody>
                        Select the toggles to turn stations radios on/off
                                          <br />
                        <b id="green-help">{green}</b> The station currently has wireless broadcasting Enabled<br /><br />
                        <b id="red-help">{red}</b> The station currently has wireless broadcasting Disabled<br /><br />
                        <span className="ap unreachable help-span" />= Radio unreachable<br />
                        <span className="ap broadcasting help-span" />= Radio is broadcasting<br />
                        <span className="ap silent help-span" />= Radio is offline<br />
                        <span className="ap pending help-span" />= Radio is pending
                      </ModalBody>
                  });
                  handleHelpShow();
                }
              }>
                <span className="span-sidebar">
                  <i className="fas fa-info"></i> Help
                  </span>
              </a>
            </Row>
            <Row className="sidebar-item">
              <a onClick={
                () => {
                  setModalData({
                    "title": "Contact Us",
                    "body":
                      <ModalBody>
                        Please contact us at:
                                        <br />
                        <a href="mailto:jeremy.foran@baicommunications.com" style={{ cursor: "pointer" }} onClick={() => {
                          navigator.clipboard.writeText("jeremy.foran@baicommunications.com");
                        }
                        }>
                          <b>jeremy.foran@baicommunications.com</b></a> with any questions or issues.
                                      </ModalBody>
                  });
                  handleHelpShow();
                }
              }>

                <span className="span-sidebar">
                  <i className="far fa-envelope"></i> Contact Us
                  </span>
              </a>
            </Row >
            <Row className="sidebar-item">
              <a onClick={async function () {
                await Auth.signOut({ global: true });
              }}>
                <span className="span-sidebar">
                  <i className="fas fa-sign-out-alt"></i> Sign Out
                  </span>
              </a>
            </Row>
            <Row className>
              <span className="bai-footer"><a href="https://www.baicommunications.com/" target="_blank" rel="noopener noreferrer">© 2021 BAI Communications Inc. </a></span>
            </Row>
          </Col>

          <Col  >
            <Container fluid className='tableContainer'>
              <Row className="justify-content-center">
                <h1 id="shutdown-h1">SHUTDOWN STATIONS:</h1>
                <Row className="all-station-toggle">
                  {shutdownLoading === false ? <Toggle
                    defaultChecked={true}
                    checked={true}
                    onChange={() => {
                      handleShutdownShow();
                    }}
                    icons={false}
                  ></Toggle> : <Toggle icons={false} checked={false} disabled={true}></Toggle>}
                </Row>
              </Row>
              <p>This will disable all currently enabled radios in the Crosslinx system</p>
              <hr></hr>
              <h2 style={{ textAlign: "left" }}>Stations</h2>
              <Row className="dTable justify-md-content-right">
                <Table className="Mtable" allStationDataCallback={setAllStationData}></Table>
              </Row>

            </Container>
          </Col>
        </Row>
      </Container>

      {/* Modals (Not always shown) */}
      {shutDownConfirmationModal}
      <ModalGen callback={handleHelpClose} show={helpModal} data={modalData} ></ModalGen>
    </div>
  ) : (
      <div>
        <NavBar bg="dark" title="CROSSLINX EMERGENCY RADIO SHUTDOWN"></NavBar>
        <AmplifyAuthenticator >


       <AmplifySignIn hideSignUp={true} slot="sign-in" usernameAlias="username" />
        </AmplifyAuthenticator>
      </div>
    );
}


export default App;