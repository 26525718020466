//Essentially just cleans the data and makes it easier to access in other functions

function cleanData (raw_data){
	var tableDict 	= {}
	var tableData 	= []

	for (var i = 0; i<raw_data.length; i++){
		var item 	= raw_data[i]
		var stationKey 	= item.stationName

		delete item['_deleted']
		delete item['_lastChangedAt']
		delete item['createdAt']
		delete item['updatedAt']


		
		if(stationKey in tableDict){
			if(tableDict.ignored!==true){
			tableDict[ stationKey ].apNames.push( item.apName )
			tableDict[ stationKey ].statuses.push( item.confirmedState )
			tableDict[ stationKey ].ids.push( { 'id' : item.id, '_version' : item._version } )
			tableDict[ stationKey ].raw.push(item)
			}

		}else{
			// Fields:
				// id: ID!
				// stationName: String
				// apName: String
				// adminDown: Boolean
				// confirmedState: Boolean
				// ignored: Boolean
				// reachable: Boolean
				// scramDown: Boolean
				// stateAuthor: String
				// radioType: String
				// ip: String
				// lastUpdatedTimestamp: String


			if(item.ignored!==true){
			tableDict[stationKey] = {'stationName':stationKey,
							'scramDown':item.scramDown === false ? 'false':'true',
							'apNames':[ item.apName ],
							'statuses':[ item.confirmedState ],
							'ids':[ { 'id' : item.id, '_version' : item._version } ],
							'raw':[item]
			}
		}
	}
}
	for(var station in tableDict){
		
		var trues 		= 0
		var unreachable = 0
		var statuses 	= tableDict[station].statuses
		var reachable 	= tableDict[station].raw

		for(var index = 0; index <tableDict[station].statuses.length; index++){
			if (statuses[index] === true){trues++}
		}
		for(var r_index = 0; i <tableDict[station].raw.length; r_index++){
			
			if (reachable[r_index].reachable === false){unreachable++}
		}
		tableDict[station].statuses = (`${trues}/${statuses.length} AP's Confirmed State,\n${unreachable} Not Reachable`)
		tableData.push(tableDict[station])
	}
	return tableData
}
export default cleanData