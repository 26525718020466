/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getScramFrontendTable = /* GraphQL */ `
  query GetScramFrontendTable($id: ID!) {
    getSCRAMFrontendTable(id: $id) {
      id
      stationName
      apName
      adminDown
      confirmedState
      ignored
      reachable
      scramDown
      stateAuthor
      radioType
      ip
      lastUpdatedTimestamp
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listScramFrontendTables = /* GraphQL */ `
  query ListScramFrontendTables(
    $filter: ModelSCRAMFrontendTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSCRAMFrontendTables(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        stationName
        apName
        adminDown
        confirmedState
        ignored
        reachable
        scramDown
        stateAuthor
        radioType
        ip
        lastUpdatedTimestamp
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncScramFrontendTables = /* GraphQL */ `
  query SyncScramFrontendTables(
    $filter: ModelSCRAMFrontendTableFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSCRAMFrontendTables(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        stationName
        apName
        adminDown
        confirmedState
        ignored
        reachable
        scramDown
        stateAuthor
        radioType
        ip
        lastUpdatedTimestamp
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
