//Mobx


//Amplify/AWS
import  { API,graphqlOperation } from 'aws-amplify'

//Libraries
import { useEffect, useState } from 'react'

//Components
import TableItem from './TableItem'

//Graphql
import {listScramFrontendTables } from '../graphql/queries';
import {onUpdateScramFrontendTable} from '../graphql/subscriptions'

//Misc
import {STATION_MAP} from '../Stores/StationStore'
import cleanData from './cleanData'

//Styling
import {Container, Spinner, Row, Col} from 'react-bootstrap'



function Table (props){
	const [fetched,setFetched] 		= useState([]);
	const [update,forceUpdate] 		= useState(false);
	const [tableData,setTableData]	= useState([]);

	//Essentially handles the websocket subscription and constatnly hydrates the tabledata with new data
	const getUpdates = async () =>{
		await API.graphql(
			graphqlOperation(onUpdateScramFrontendTable)
	   ).subscribe({
		   next: ({ provider, value }) =>{
				var update 	  = value.data.onUpdateSCRAMFrontendTable
				if (update !== null){
					var tempFetched = fetched;
					const indexFet = tempFetched.findIndex((ap) => ap.id === update.id);
					if(indexFet!==-1){
						tempFetched[indexFet] = update;
						handleFetched(tempFetched,props.allStationDataCallback)
					}else{
					}
		   } 
		}
	   });

	}

	 const handleFetched          = (raw,callback) => {

		var cleaned 	= cleanData(raw)

		setTableData(cleaned)
		setFetched(raw)
		callback(cleaned.reduce((lambdaData,station)=> lambdaData.concat(station.raw),[]))
		forceUpdate(true);
	 }

	// Estanblish Websocket Connection upon initial render and Update our data states as new data is received from Golang Agent/Other Users
	useEffect(  () => {
		getUpdates();
	},[update])


	// the intial pulling of all the data from the dynamo table via graphql after the user authenitcates
	useEffect(()=>{
		async function initEvent(){
		const query = await API.graphql({query: listScramFrontendTables,variables: {limit:400}})
		handleFetched(query.data.listSCRAMFrontendTables.items,props.allStationDataCallback)
		}
		initEvent()
	},[])



	//creating table entries with the cleaned data 
	if ( fetched.length !== 0 ) {
		
		const cells1 = [];
		const cells2 = [];

		//Sorts the stations so they display in the order Jeremy wants
		var sortedStations = []		
		Object.keys(STATION_MAP).map((key,index)=>{
			for(var i = 0; i<tableData.length; i++){
				if(key === tableData[i].stationName){
					sortedStations.push(tableData[i]);
				}

			}
		})
		
		// Splits the stations in 2 to be displyed in the 2 columns
		for( var i=0; i < Math.floor(sortedStations.length/2)+1 ; i++ ){
			cells1.push(<TableItem station = {sortedStations[i]} />)
		}
		for(var j = Math.floor(sortedStations.length/2)+1; j<sortedStations.length; j++){
			cells2.push(<TableItem className = "table-item" station = {sortedStations[j]} />)
		}
		return(

			<Container fluid>
				<Row>
					<Col id="left-col" className="table-row ">{cells1}</Col>
					<Col className= "table-row">{cells2}</Col>
				</Row>
			</Container>


	);}
	else return (
		<Row className="justify-content-md-center">
			<Spinner animation="border" variant="secondary" /> 
		</Row>
	)
	

}


export default Table;